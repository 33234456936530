<template>
    <div>
        <common-header>{{currentText}}</common-header>
        <div class="container">
            <!-- <iframe src="http://220.160.52.192/med/rest/med/loginform.htm" width="100%" height="800" frameborder="0" scrolling="auto" id="iframename" name="iframename"></iframe> -->
            <iframe :src="currentSrc" width="100%" height="800" frameborder="0" scrolling="auto" id="iframename" name="iframename"></iframe>
        </div>
    </div>
</template>
<script>
import CommonHeader from '@/components/header'
export default {
    data() {
        return {
            currentSrc: '',
            currentText: '',
            iconList: [{
                id: 1,
                iconSrc: require('@/assets/images/icon-xgymyy@2x.png'),
                text: '新冠疫苗預約',
                href: 'http://appweb.dalutone.cn/app/vaccin'
            }, {
                id: 2,
                iconSrc: require('@/assets/images/icon-mineyb.png'),
                text: '新冠疫情便民',
                href: 'https://mztapp.fujian.gov.cn:8190/mztAppWeb/app/ncov2019/newIndex.jsp'
            }, {
                id: 3,
                iconSrc: require('@/assets/images/icon-jwlmjkm@2x.png'),
                text: '境外入闽健康碼',
                href: 'https://mztapp.fujian.gov.cn:8200/fjyqhm/app/bmjwrmjkm/home.jsp'
            }, {
                id: 4,
                iconSrc: require('@/assets/images/icon-sbfw@2x.png'),
                text: '医保服務',
                href: 'http://220.160.52.192/med/rest/med/loginform.htm'
            }, {
                id: 5,
                iconSrc: require('@/assets/images/icon-ybfw@2x.png'),
                text: '社保服務',
                // href: 'http://rst.fujian.gov.cn/m/#!/'
                href: ''
            }]
        }
    },
    components: {
        CommonHeader
    },
    mounted() {
        this.currentSrc = this.iconList[this.$route.query['id'] - 1]['href']
        this.currentText = this.iconList[this.$route.query['id'] - 1]['text']
        console.log('...,', this.currentSrc, this.currentText)
    }
}
</script>
<style lang="scss" scoped>
.container {
    width: 100%;
    padding-top: 44px;
    overflow-x: hidden;
}
</style>